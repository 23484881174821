import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { BasicContent } from '../components';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { IoMdSend } from 'react-icons/io';
import { ProgressBar } from '../../Process/List/styles';
import {
    MovimentacaoProcesso,
    CheckList,
    // MemorialEmpreendimento,
    IndicadoresAmbientais,
    ApiaResumo,
    MensagensProcesso,
    AnaliseTecImpacto,
    AnaliseImpactoAmbiental,
} from '~/views/Process/components';
import {
    showProcesso,
    enviarParaAutorizacao,
} from '~/store/modules/processo/action';
import { storeAnalise } from '~/store/modules/analise/action';
import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
import {
    loadCondicionantesProcesso,
    getProcessoRef,
    // getProcessoCCLA,
} from '~/store/modules/processo/action';
import {
    ParecerProjeto,
    // EstudosAmbientais,
    // NotificacaoTecnicaAmbiental,
    NotaTecAmbiental,
    AgendaVisionariaTecAmb,
    RelatorioVistoria,
    CondicionantesAmbientais,
    Autorizacao,
    // AnaliseTecImpacto
} from './components';
import Swal from 'sweetalert2';
import ModalLegislacoes from '~/components/ModalLegislacoes';
import ModalListaRef from '~/components/ModalLicencaRef';
// import ModalCCLA from '~/components/ModalCCLA';
import { Retificacao } from '../../Process/components';
import { Certidao } from '../../Process/Detalhe/components';

const Show = () => {
    let { processo_id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const { processo, lista_processos_ref, status_code } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );
    const { cond_geral, cond_especifica } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );
    const { relatorio_vistoria } = useSelector(
        ({ RelatorioVistoriaReducer }) => RelatorioVistoriaReducer
    );
    const { indicador_processo } = useSelector(
        ({ IndicadorProcessoReducer }) => IndicadorProcessoReducer
    );
    const { indicadores_ambientais } = useSelector(
        ({ IndicadorAmbientalReducer }) => IndicadorAmbientalReducer
    );
    const [parecerIsOk, setParecerIsOk] = useState(false);
    const [indicadoresIsOk, setIndicadoresIsOk] = useState(false);
    const [docsTecIsOk, setDocsTecIsOk] = useState(false);
    const [condicionateIsOk, setCondicionantesIsOk] = useState(false);
    const [notaTecIsOk, setNotaTecIsOk] = useState(false);
    const [agendaVistoriaIsOk, setAgendaVistoriaIsOk] = useState(false);
    const [relatorioIsOk, setRelatorioIsOk] = useState(false);
    const [showLegisModal, setShowLegisModal] = useState(false);
    const [showLicencaRefModal, setShowLicencaRefModal] = useState(false);
    // const [showModalCCLA, setShowModalCCLA] = useState(false);
    const [sendingForDistribution, setSendingForDistribution] = useState(false);

    useEffect(() => {
        dispatch(loadCondicionantesProcesso(processo_id));
    }, [processo_id, dispatch]);

    useEffect(() => {
        dispatch(getProcessoRef(processo_id));
    }, [processo_id, dispatch]);

    // useEffect(() => {
    //     if (processo?.protocolo) {
    //         dispatch(getProcessoCCLA(processo.protocolo));
    //     }
    // }, [processo, dispatch]);

    useEffect(() => {
        dispatch(
            showProcesso(processo_id, {
                with:
                    'analise_processo,' +
                    'analise_previa,' +
                    'pessoa,' +
                    'documentos,' +
                    'estado,' +
                    'municipio,' +
                    'atividade.legislacao_atividade;deleted_at is null,' +
                    'pessoa_responsavel_tecnico,' +
                    'tipo_licenca,' +
                    'status_processo,' +
                    'porte_empreendimento,' +
                    'projeto,' +
                    'vistorias.relatorio,' +
                    'entidade,' +
                    'tramites,' +
                    'grupo_atividades',
            })
        );
    }, [dispatch, processo_id, relatorio_vistoria]);

    useEffect(() => {
        dispatch(
            loadIndicadoresAmbientais({
                where: `processo_id:${processo_id}`,
            })
        );
    }, [processo_id, dispatch, indicador_processo]);

    useEffect(() => {
        if (processo.documentos?.length > 0) {
            setDocsTecIsOk(true);
        }
    }, [processo.documentos]);

    useEffect(() => {
        if (indicadores_ambientais && indicadores_ambientais.length > 0) {
            indicadores_ambientais.forEach((indicador) => {
                if (indicador.valor) {
                    setIndicadoresIsOk(true);
                }
            });
        }
    }, [indicadores_ambientais]);

    const handleStartAnalise = async () => {
        try {
            let result = await Swal.fire({
                title: 'Iniciar Análise?',
                text: 'Deseja Iniciar Análise de Impacto Ambiental?!',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (result.value) {
                await dispatch(
                    storeAnalise({ processo_id, analise_previa: false })
                );
                await Swal.fire(
                    'Sucesso',
                    'Analise iniciada com sucesso!',
                    'success'
                );
                await history.go();
            }
        } catch (error) {
            await Swal.fire('Erro', 'Erro ao Iniciar Analise', 'error');
        }
    };

    const handleEnviarAutorizacao = async () => {
        try {
            let result = await Swal.fire({
                title: 'Enviar para Autorização?',
                text: 'o processo entrará em processo de autorização.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (result.value) {
                await dispatch(enviarParaAutorizacao(processo_id));
                const { value, isConfirmed } = await Swal.fire(
                    'Sucesso',
                    'Enviado com sucesso!',
                    'success'
                );
                setSendingForDistribution(true);
                if (status_code === 200 && isConfirmed && value === true) {
                    history.go(0);
                }
            }
        } catch (error) {
            await Swal.fire('Erro', 'Erro ao Iniciar Analise', 'error');
        }
    };

    return (
        <BasicContent
            breadcrumb={{
                label: 'Analise Processo',
                to: '/analise-processo',
                active: true,
            }}
            title="Detalhes Processo."
        >
            <ModalLegislacoes
                closeModal={() => setShowLegisModal(false)}
                legislacoes={
                    Object.keys(processo).length && processo?.atividade
                        ? processo?.atividade?.legislacao_atividade
                        : []
                }
                showLegisModal={showLegisModal}
            />
            <ModalListaRef
                closeModal={() => setShowLicencaRefModal(false)}
                licencas={lista_processos_ref}
                showLicencasModal={showLicencaRefModal}
            />
            {/* {lista_processos_ccla?.data?.length > 0 && (
                <ModalCCLA
                    closeModal={() => setShowModalCCLA(false)}
                    data={lista_processos_ccla.data}
                    showModal={showModalCCLA}
                />
            )} */}

            <div className="card">
                {processo &&
                processo.protocolo &&
                processo.pessoa_responsavel_tecnico ? (
                    <>
                        <div className="card-header">
                            <h3 className="card-title">
                                PROCESSO : <strong>{processo.protocolo}</strong>
                            </h3>
                            <div className="card-tools">
                                <Link
                                    className="btn  btn-default"
                                    to="/analise-processo"
                                >
                                    <FaArrowAltCircleLeft />
                                    &nbsp; Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <th>Processo</th>
                                        <td>{processo.protocolo}</td>
                                    </tr>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <td>
                                            {' '}
                                            {`${processo.pessoa?.nome} - ${
                                                processo.pessoa?.cnpj
                                                    ? formatForMask(
                                                        processo.pessoa?.cnpj,
                                                        '##.###.###/####-##'
                                                    )
                                                    : ''
                                            } ${
                                                processo.pessoa?.cpf
                                                    ? formatForMask(
                                                        processo.pessoa?.cpf,
                                                        '###.###.###-##'
                                                    )
                                                    : ''
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Porte Empreendimento</th>
                                        <td>
                                            {processo.porte_empreendimento &&
                                                processo.porte_empreendimento
                                                    ?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>
                                            {' '}
                                            {processo.municipio?.nome} -{' '}
                                            {processo.estado?.uf}
                                        </td>
                                    </tr>
                                    {processo.entidade?.cnae_group &&
                                    processo.grupo_atividades ? (
                                        <>
                                            <tr>
                                                <th>
                                                    Atividade Principal
                                                    Licenciada
                                                </th>
                                                <td>
                                                    {' '}
                                                    {processo.atividade?.nome}
                                                </td>
                                            </tr>
                                            {processo.grupo_atividades.map(
                                                (grupo, index) => (
                                                    <tr key={index}>
                                                        <th>
                                                            Atividade(s)
                                                            Secundária(s)
                                                            Licenciada(s)
                                                        </th>
                                                        <td>{grupo.nome}</td>
                                                    </tr>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <th>Atividade</th>
                                            <td> {processo.atividade?.nome}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Endereço da atividade</th>
                                        <td>
                                            {processo.logradouro}
                                            &nbsp;-&nbsp;
                                            {processo.numero_endereco ??
                                                'Nº não informado'}
                                            &nbsp;-&nbsp;
                                            {processo.bairro}
                                            &nbsp;-&nbsp;
                                            {processo.cep}
                                            &nbsp;-&nbsp;
                                            {processo.complemento ??
                                                'Sem complemento.'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tipo de licença</th>
                                        <td> {processo.tipo_licenca?.nome}</td>
                                    </tr>
                                    <tr>
                                        <th>Técnico Consultor</th>
                                        <td>
                                            {processo.pessoa_responsavel_tecnico &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    ?.pessoa_tecnico_profissional &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    ?.pessoa_tecnico_profissional
                                                    ?.nome}{' '}
                                            -{' '}
                                            {processo.pessoa_responsavel_tecnico &&
                                            processo.pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico &&
                                            processo.pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional &&
                                            processo.pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional
                                                ?.cnpj
                                                ? formatForMask(
                                                    processo
                                                        .pessoa_responsavel_tecnico
                                                        ?.responsavel_tecnico
                                                        ?.pessoa_tecnico_profissional
                                                        ?.cnpj,
                                                    '##.###.###/####-##'
                                                )
                                                : '' -
                                                    processo
                                                        .pessoa_responsavel_tecnico
                                                        ?.responsavel_tecnico
                                                        ?.pessoa_tecnico_profissional
                                                        ?.cpf
                                                ? formatForMask(
                                                    processo
                                                        .pessoa_responsavel_tecnico
                                                        ?.responsavel_tecnico
                                                        ?.pessoa_tecnico_profissional
                                                        ?.cpf,
                                                    '###.###.###-##'
                                                )
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Endereço da Empresa</th>
                                        <td>
                                            {processo.pessoa?.logradouro},
                                            {processo.pessoa?.bairro} -
                                            {processo.pessoa?.cep} -
                                            {processo.pessoa?.cidade}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Legislações</th>
                                        <td>
                                            <button
                                                className="btn btn-default"
                                                onClick={() =>
                                                    setShowLegisModal(true)
                                                }
                                                type="button"
                                            >
                                                Visualizar
                                            </button>
                                        </td>
                                    </tr>
                                    {lista_processos_ref.length > 0 && (
                                        <tr>
                                            <th>Processos de referência</th>
                                            <td>
                                                <button
                                                    className="btn btn-default"
                                                    onClick={() =>
                                                        setShowLicencaRefModal(
                                                            true
                                                        )
                                                    }
                                                    type="button"
                                                >
                                                    Visualizar
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Cadeia de Custória - CCLA</th>
                                        <td>
                                            <a
                                                className="btn btn-default"
                                                target="_blank"
                                                // onClick={() =>
                                                //     setShowModalCCLA(true)
                                                // }
                                                type="button"
                                                href={`/cadeia_custodia/${processo?.protocolo}/${processo?.id}/imprimir`}
                                            >
                                                Visualizar
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className="badge badge-success">
                                                {processo.status_processo?.nome}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Progresso</th>
                                        <td className="project_progress">
                                            <div className="progress progress-sm">
                                                <ProgressBar
                                                    ariaVolumemax="100"
                                                    ariaVolumemin="0"
                                                    ariaVolumenow={
                                                        (processo.passo * 100) /
                                                        20
                                                    }
                                                    className="progress-bar bg-green"
                                                    percents={
                                                        (processo.passo * 100) /
                                                        20
                                                    }
                                                    role="progressbar"
                                                />
                                            </div>
                                            <small>
                                                {(processo.passo * 100) / 20}%
                                                completo
                                            </small>
                                        </td>
                                    </tr>

                                    {processo.status_processo?.id === 10 && (
                                        <>
                                            {/* {parecerIsOk &&
                                                docsTecIsOk &&
                                                condicionateIsOk &&
                                                notaTecIsOk &&
                                                agendaVistoriaIsOk &&
                                                relatorioIsOk &&
                                                ((processo.tipo_licenca
                                                    ?.has_apia_atia &&
                                                    indicadoresIsOk) ||
                                                    !processo.tipo_licenca
                                                        ?.has_apia_atia) && (
                                                        */}
                                            {parecerIsOk &&
                                                docsTecIsOk &&
                                                condicionateIsOk &&
                                                notaTecIsOk &&
                                                // Verifica se `has_vistoria_tecnica` é `true` para exigir os dois checks
                                                (processo.tipo_licenca
                                                    ?.has_vistoria_tecnica
                                                    ? agendaVistoriaIsOk &&
                                                        relatorioIsOk
                                                    : true) &&
                                                // Verifica `has_apia_atia` para exigir `indicadoresIsOk`
                                                (processo.tipo_licenca
                                                    ?.has_apia_atia
                                                    ? indicadoresIsOk
                                                    : true) && (
                                                    <tr>
                                                        <td
                                                            className="text-center"
                                                            colSpan={2}
                                                        >
                                                            {sendingForDistribution ? (
                                                                <Loading />
                                                            ) : (
                                                                <button
                                                                    className="btn btn-success m-3"
                                                                    onClick={
                                                                        handleEnviarAutorizacao
                                                                    }
                                                                >
                                                                    {' '}
                                                                    Enviar
                                                                    processo
                                                                    para
                                                                    autorização?{' '}
                                                                    <IoMdSend />
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="row">
                {(processo.status_processo?.id >= 10 ||
                    processo?.status_processo?.id === 7) && (
                    <>
                        <div className="col-md-4">
                            <ParecerProjeto
                                onlyView={processo?.status_processo?.id === 7}
                                processo_id={processo.id}
                                projeto={processo.projeto}
                                setParecerIsOk={setParecerIsOk}
                            />
                        </div>
                        {/* <div className="col-md-4">
                            <MemorialEmpreendimento
                            processo_id={processo.id}
                            tipo_licenca_id={processo.tipo_licenca.id}

                            />
                        </div> */}
                    </>
                )}

                {processo.tipo_licenca?.has_apia_atia && (
                    <>
                        {processo ? (
                            <div className="col-md-4">
                                {!processo.analise_processo ? (
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Análise Técnica de Impacto
                                                Ambiental
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <button
                                                className="btn btn-success btn-block"
                                                onClick={handleStartAnalise}
                                            >
                                                Iniciar Análise de Impacto
                                                Ambiental
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    processo.tipo_licenca && (
                                        <AnaliseTecImpacto
                                            analise_id={
                                                processo.analise_processo?.id
                                            }
                                            analise_previa={false}
                                            processo_id={processo.id}
                                            tipo_licenca_id={
                                                processo.tipo_licenca.id
                                            }
                                        />
                                    )
                                )}
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </>
                )}

                <div className="col-md-4">
                    <CondicionantesAmbientais
                        cond_especifica={cond_especifica}
                        cond_geral={cond_geral}
                        onlyView={processo.status_processo_id === 7}
                        processo_id={processo_id}
                        setCondicionantesIsOk={setCondicionantesIsOk}
                        has_apia_atia={processo?.tipo_licenca?.has_apia_atia}
                    />
                </div>

                {processo.tipo_licenca?.has_apia_atia && (
                    <div className="col-md-4">
                        {processo &&
                        processo.analise_previa &&
                        processo.tipo_licenca ? (
                            <ApiaResumo
                                analise_id={processo.analise_previa?.id}
                                processo_id={processo.id}
                                tipo_licenca_id={processo.tipo_licenca.id}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}

                {processo.tipo_licenca?.has_apia_atia &&
                    processo.analise_processo?.concluida && (
                        <div className="col-md-4">
                            <AnaliseImpactoAmbiental />
                        </div>
                    )}

                <div className="col-md-4">
                    <MovimentacaoProcesso processo_id={processo_id} />
                </div>
                <div className="col-md-4">
                    <CheckList processo_id={processo_id} />
                </div>

                {processo?.tipo_licenca?.has_apia_atia && (
                    <div className="col-md-4">
                        <IndicadoresAmbientais
                            actions={false}
                            indicadores_ambientais={indicadores_ambientais}
                            processo_id={processo_id}
                        />
                    </div>
                )}

                <div className="col-md-4">
                    <MensagensProcesso processo={processo} />
                </div>
                <div className="col-md-4">
                    <NotaTecAmbiental
                        onlyView={processo.status_processo_id === 7}
                        processo_id={processo_id}
                        setNotaTecIsOk={setNotaTecIsOk}
                    />
                </div>

                {processo?.tipo_licenca?.has_vistoria_tecnica && (
                    <div className="col-md-4">
                        <AgendaVisionariaTecAmb
                            onlyView={processo.status_processo_id === 7}
                            processo_id={processo_id}
                            setAgendaVistoriaIsOk={setAgendaVistoriaIsOk}
                        />
                    </div>
                )}

                {processo.vistorias && processo.vistorias?.length > 0 && (
                    <div className="col-md-4">
                        <RelatorioVistoria
                            onlyView={processo.status_processo_id === 7}
                            setRelatorioIsOk={setRelatorioIsOk}
                            vistorias={processo.vistorias}
                        />
                    </div>
                )}

                {[7, 8, 9, 10, 11].includes(processo.status_processo_id) && (
                    <div className="col-md-4">
                        <Retificacao />
                    </div>
                )}
                <div className="col-md-4">
                    <Autorizacao
                        entidade_id={processo.entidade_id}
                        processo_id={processo_id}
                        status_processo_id={processo.status_processo_id}
                    />
                </div>
                <Certidao processo={processo} />
            </div>
        </BasicContent>
    );
};

export default Show;
